import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import { useTokenState, useCurrencyState, useListingState } from "src/state";
import { round } from "src/helpers/number.helper";
import { Link } from "react-router-dom";
import SquareBlock from "src/components/frontend/SquareBlock";

import metamaskIcon from "src/themes/default/assets/img/metamask-icon.svg";
import { cachedVersion } from "src/helpers/file.helper";

const ListingItemName = styled("h2")(({ theme }) => ({
	fontSize: "18px",
	[theme.breakpoints.up("sm")]: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
}));

const ListingItemDescription = styled("p")(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
}));

const useStyles = makeStyles(theme => {
	return {
		listingImage: {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			verticalAlign: "bottom",
		},
		listingBlock: {
			border: "1px solid #999",
			borderRadius: "10px",
			background: "#FFF",
			overflow: "hidden",
			boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.3), 0px 10px 20px rgba(0,0,0,0.3)",
		},
		listingItemContent: {
			padding: "15px",
		},
		listingItemPrice: {
			padding: "15px",
			background: "#611316",
			color: "#FFF",
		},
	};
});

const MVCOLLECTIONS = ["0x072c20AcC9DE41f16bEc3E1A1B1Cd5e22B0e41e4", "0x511c91A7FD9b53D1f0cEA7A9e06a66Dc285158Af"];

const ListingItem = ({ listing, name, description, linkTo, gridOptions, showEmpty, ...props }) => {
	const classes = useStyles();

	const { promised: isTokenLoading, getToken } = useTokenState(listing.collectionID);
	const { promised: isListingLoading, getListingByTokenID } = useListingState();

	const { getExRateInUSD } = useCurrencyState();

	const token = getToken(listing.collectionID, listing.tokenID);

	const tokenListing = listing.type ? listing : getListingByTokenID(listing.collectionID, listing.tokenID, true);

	if ((!token || !token.imageUrl) && !showEmpty) return null;

	return isTokenLoading || isListingLoading ? null : (
		<Box className="col-12 col-sm-6 col-md-4">
			<Link
				to={linkTo ? linkTo : `/listing/${listing.collectionID}/${token.tokenID}`}
				style={{ color: "inherit" }}
			>
				<Box className="listing-container">
					{token.animationUrl && token.animationFileType === "video" ? (
						<video className="listing-video" width="100%" autoPlay muted playsInline loop>
							<source src={cachedVersion(token.animationUrl, "video", 600)} type="video/mp4" />
						</video>
					) : (
						<video className="listing-video" width="100%" autoPlay muted playsInline loop>
							<source src="/assets/temp/first.mp4" type="video/mp4" />
						</video>
					)}
					<div className="listing-content">
						{!MVCOLLECTIONS.includes(listing.collectionID) && (
							<div className="listing-img">
								<img src={token ? cachedVersion(token.imageUrl) : metamaskIcon} />
							</div>
						)}
						<div className={`listing-description ${MVCOLLECTIONS.includes(listing.collectionID) ? 'bigger' : ''}`}>
							<h4>{name ? name : token.itemName}</h4>
							<p style={{ whiteSpace: "pre-line" }}>{description}</p>
						</div>
					</div>
				</Box>
			</Link>
		</Box>
	);
};

export default ListingItem;
