import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { AppBar, Toolbar, experimentalStyled, Button, Box, Typography } from "@material-ui/core";
import { styled, alpha } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../components/Logo";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { shortAccount } from "src/helpers/blockchain.helper";
import { useContext, useEffect } from "react";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "src/components/PopperMenu";
import { useGlobalState } from "src/state";
import HeaderModule from "src/components/frontend/partials/HeaderModule";

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	border: "1px solid #CCC",
	borderRadius: "10px",
	backgroundColor: alpha(theme.palette.common.white, 1),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.75),
	},
	// boxShadow: "0px 0px 0px 1px #eee, 0px 10px 20px #eee",
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: "100%",
	// [theme.breakpoints.up("sm")]: {
	//   marginLeft: theme.spacing(3),
	// },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	color: "#939393",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	flexGrow: 1,
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
	},
}));

const MainLayoutWrapper = experimentalStyled("div")({
	display: "flex",
	flex: "1 1 auto",
	//overflow: "hidden",
});

const MainNavbar = props => {
	const blockchainInfo = useContext(BlockchainContext);
	const { setRedirectToAfterConnect } = useGlobalState();
	const location = useLocation();

	const navigate = useNavigate();

	const connect = () => {
		setRedirectToAfterConnect(location.pathname);

		//if (blockchainInfo) blockchainInfo.connectToBlockchain();
		navigate("/connect-wallet");
	};

	const search = value => {
		navigate("/search/" + value);
	};

	// Sticky Menu Area
	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	});

	/* Method that will fix header after a specific scrollable */
	const isSticky = e => {
		const header = document.querySelector(".navbar.fixed-top");
		const scrollTop = window.scrollY;
		scrollTop >= 100 ? header.classList.add("menu-bg") : header.classList.remove("menu-bg");
	};

	return (
		<header id="home" className="hero-area">
			<nav className="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
				<div className="container">
					<div>
						<a href="https://xva.asia" className="navbar-brand animated fadeIn">
							<img src="/assets/img/logo.png" style={{ maxHeight: "90px" }} alt="" />
						</a>
					</div>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarCollapse"
						aria-controls="navbarCollapse"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<i className="lni-menu"></i>
					</button>
					<div className="collapse navbar-collapse" id="navbarCollapse">
						<ul className="navbar-nav mr-auto w-100 justify-content-end">
							<li className="nav-item">
								<a className="nav-link" href="https://xva.asia">
									Home
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link selected" href="/">
									NFT Marketplace
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link page-scroll" href="https://marketplace.xva.asia/listing/0x425460706940063Aa4c76441efdD43A3A25694aA/1">Auction</a>
							</li>
							<li className="nav-item">
								<a className="nav-link page-scroll" href="https://xva.asia#xva-access">
									XVA Access
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link page-scroll" href="https://xva.asia#team">
									About Us
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link page-scroll" href="https://xva.asia#faq">FAQ</a>
							</li>
							<li className="nav-item">
								<a className="nav-link page-scroll" href="https://xva.asia#contact">
									Contact Us
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};

export default MainNavbar;
