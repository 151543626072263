import { Box } from "@material-ui/core";
import { useSettingState } from "src/state";

const MainFooter = () => {
	const { promised: isSettingsLoading, getSetting } = useSettingState();

	return isSettingsLoading ? null : (
		<footer>
			<section id="footer-Content">
				<div className="container">
					<div className="row" style={{ justifyContent: "space-between", alignItems: "flex-end" }}>
						<div className="col-lg-3 col-md-6 col-12">
							<h6>XVA Collective Pte Ltd</h6>
							<p>
								328 North Bridge Rd, #02-20
								<br />
								Raffles Hotel Arcade,
								<br />
								Singapore 188719.
							</p>
						</div>
						<div className="col-lg-6 col-md-6 col-12">
							<div className="widget text-right">
								<p>&copy; 2022 XVA COLLECTIVE PTE LTD - ALL RIGHTS RESERVED.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</footer>
	);
};

export default MainFooter;
