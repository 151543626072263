import React, { useContext, useEffect, useRef, useState } from "react";
import Hero from "src/components/frontend/Hero";
import Loading from "src/components/Loading";
import { useListingState, useCollectionState, useSettingState, useProjectsState } from "src/state";
import Collections from "src/components/frontend/Collections";
import Projects from "src/components/frontend/Projects";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { Box } from "@material-ui/core";
import Modal from "src/components/Modal";
import YoutubeVideoPlayer from "src/components/frontend/YoutubeVideoPlayer";
import { pluck } from "src/helpers/array.helper";
import { cachedVersion } from "src/helpers/file.helper";

const MVCOLLECTIONS = ["0x072c20AcC9DE41f16bEc3E1A1B1Cd5e22B0e41e4", "0x511c91A7FD9b53D1f0cEA7A9e06a66Dc285158Af"];

const HeaderArtwork = () => {
	return (
		<div className="hero-area">
			<div className="container">
				<div className="row space-100">
					<div className="col-lg-6 col-md-12 col-12">
						<div className="contents">
							<p>
								<img
									src="/assets/img/logo.png"
									style={{ maxHeight: "150px", marginBottom: "25px" }}
									alt=""
								/>
							</p>
							<h2 className="section-title">brings you the future of luxury asset ownership...</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const MVPage = () => {
	// const blockchainInfo = useContext(BlockchainContext);
	const {
		promised: isListingLoading,
		listings,
		//fetch: fetchListings,
	} = useListingState();
	const { promised: isCollectionsLoading, collections } = useCollectionState();
	const { promised: isProjectsLoading, projects } = useProjectsState();
	const featured = useRef(-1);

	const [openVideoPopup, setOpenVideoPopup] = useState(false);
	const [playingVideo, setPlayingVideo] = useState(false);

	const [listedCollections, setListedCollections] = useState([]);
	const collectionsLoaded = useRef(false);

	// useEffect(() => {
	//   if (blockchainInfo.networkId)
	//     fetchListings(blockchainInfo.networkId);
	// }, [blockchainInfo]);

	useEffect(() => {
		if (!collectionsLoaded.current && !isListingLoading) {
			const collectionWithListings = pluck(listings, "collectionID");
			setListedCollections(
				collections.filter(
					item => item.chainID === 56 //&& (collectionWithListings.includes(item.address) || item.enabled)
					// blockchainInfo &&
					// (blockchainInfo.networkId
					//   ? item.chainID === blockchainInfo.networkId
					//   : item.chainID === 137)
					&& MVCOLLECTIONS.includes(item.address)
				) ?? {}
			);
			collectionsLoaded.current = true;
		}
	}, [collections, isListingLoading]);

	// if (isProjectsLoading || isCollectionsLoading) return <Loading />;

	// if (featured.current < 0 && listedCollections.length)
	//   featured.current = Math.floor(Math.random() * listedCollections.length);

	const openVideo = videoURL => {
		setPlayingVideo(videoURL);
		setOpenVideoPopup(true);
	};

	const handleModalClose = () => {
		setOpenVideoPopup(false);
		setPlayingVideo(false);
	};

	return (
		<>
			<HeaderArtwork />

			{!process.env.REACT_APP_HAVE_PROJECTS && isCollectionsLoading ? (
				<Loading />
			) : !process.env.REACT_APP_HAVE_PROJECTS && collections.length > 0 ? (
				<Collections collections={listedCollections} title="Collectible" />
			) : null}
			<Modal
				open={openVideoPopup}
				fullWidth={true}
				onClose={handleModalClose}
				content={<YoutubeVideoPlayer youtubeUrl={playingVideo} />}
			/>
		</>
	);
};

export default MVPage;
