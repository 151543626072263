import { useEffect, useRef, useState, useContext } from "react";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import {
	Box,
	Button,
	CircularProgress,
	Typography,
	Grid,
	Switch,
	FormGroup,
	FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { readBlobToBuffer, uploadFileToIPFS } from "src/helpers/file.helper";
import jsonObjectTemplate from "src/metadata/ERC721-vineart";
import TextField from "src/components/FormikTextField";
import ImagePicker from "src/components/ImagePicker";
import { isValidAddress, networkName, shortAccount } from "src/helpers/blockchain.helper";
import { hash_to_ipfs_link } from "src/helpers/links.helper";

const ST = require("stjs");

const useStyles = makeStyles(theme => ({
	fileInput: {
		display: "none",
	},
}));

const ItemForm = ({ defaultData, onCreateItem, toValidateSku, ...props }) => {
	const blockchainInfo = useContext(BlockchainContext);
	const classes = useStyles();
	const [selectedImage, setSelectedImage] = useState("");
	const [selectedAnimationFile, setSelectedAnimationFile] = useState("");
	const [selectedCertificate, setSelectedCertificate] = useState("");
	const [haveAnimation, setHaveAnimation] = useState(false);

	const selectedImageBuffer = useRef(null);
	const selectedAnimationBuffer = useRef(null);
	const selectedAnimationFileType = useRef(null);
	const selectedCertificateBuffer = useRef(null);

	useEffect(() => {
		return function () {
			if (selectedImage) {
				URL.revokeObjectURL(selectedImage);
				selectedImageBuffer.current = null;
			}
		};
	}, [selectedImage]);

	const handleFileSelect = (filename, file) => {
		if (file && file.type.match("^image/")) {
			readBlobToBuffer(file).then(imageBuffer => (selectedImageBuffer.current = imageBuffer));
			setSelectedImage(URL.createObjectURL(file));
		}
	};

	const handleAnnimationSelect = (filename, file) => {
		if (file && (file.type.match("^image/gif") || file.type.match("^video/"))) {
			readBlobToBuffer(file).then(imageBuffer => (selectedAnimationBuffer.current = imageBuffer));
			setSelectedAnimationFile(URL.createObjectURL(file));

			const fileType = file.type.match("^video/") ? "video" : "image";
			selectedAnimationFileType.current = fileType;
		}
	};

	const handleGotAnimation = () => setHaveAnimation(!haveAnimation);

	const handleCertificateSelect = event => {
		event.preventDefault();
		const file = event.target.files[0];
		if (file) {
			readBlobToBuffer(file).then(fileBuffer => (selectedCertificateBuffer.current = fileBuffer));
			setSelectedCertificate(file.name);
		}
	};

	const formik = useFormik({
		initialValues: {
			item_sku: "",
			item_name: defaultData ? defaultData.item_name : "",
			description: defaultData ? defaultData.description : "",
			units_in_box: defaultData ? defaultData.units_in_box : "",
			year: defaultData ? defaultData.year : "",
			lot_number: defaultData && defaultData.lot_number ? defaultData.lot_number : "",
			product_code: defaultData ? defaultData.product_code : "",
			origin: defaultData ? defaultData.origin : "",
			manufactured_at: defaultData ? defaultData.manufactured_at : "",
			availability: defaultData ? defaultData.availability : "",
			wine_type: defaultData ? defaultData.wine_type : "",
			wallet_address: "",
		},
		onSubmit: async (values, formikHelpers) => {
			const jsonObj = ST.select(values).transformWith(jsonObjectTemplate).root();

			try {
				const imageHash = await uploadFileToIPFS(selectedImageBuffer.current);
				jsonObj.image = hash_to_ipfs_link(imageHash);

				let certificateHash = null;

				if (selectedCertificate && selectedCertificateBuffer.current) {
					certificateHash = await uploadFileToIPFS(selectedCertificateBuffer.current);
					jsonObj.certificate_url = hash_to_ipfs_link(certificateHash);
				}

				let animationHash = null;
				if (haveAnimation && selectedAnimationBuffer.current) {
					animationHash = await uploadFileToIPFS(selectedAnimationBuffer.current, selectedAnimationFileType.current);
					jsonObj.animation_url = hash_to_ipfs_link(animationHash);
				}

				// upload the metadata to ipfs
				const jsonBuffer = Buffer.from(JSON.stringify(jsonObj));
				const jsonHash = await uploadFileToIPFS(jsonBuffer, 'json');

				formikHelpers.setSubmitting(false);
				// return back the data to parent page
				onCreateItem &&
					onCreateItem({
						item_sku: values.item_sku,
						metadata_hash: jsonHash,
						image_url: jsonObj.image,
						metadata: values,
						wallet_address: values.wallet_address,
						certificate_hash: certificateHash,
						animation_url: jsonObj.animation_url ?? null,
						animation_file_type: selectedAnimationFileType.current,
					});
			} catch (error) {
				formikHelpers.setSubmitting(false);
				console.error(error);
				return;
			}
		},
		validationSchema: Yup.object().shape({
			item_sku: Yup.string()
				.max(30)
				.required("Please fill in the Item SKU")
				.test("item_sku", "This SKU has already been added", function (item_sku) {
					return !toValidateSku || toValidateSku(item_sku);
				}),
			item_name: Yup.string().max(100).required("Item Name is required"),
			units_in_box: Yup.number()
				.required("Please provide the number of units per box/crate")
				.min(1, "Number of units per box/crate must be atleast 1"),
			wallet_address: Yup.string().test("is_valid", "This address is not valid", function (value) {
				return !value || isValidAddress(value);
			}),
		}),
	});

	return (
		<FormikProvider value={formik}>
			<Form>
				<Typography mb={2} variant="h5">
					Please ensure the details you adding are correct, as these can not be modified once created
				</Typography>
				<ImagePicker
					withDragDrop
					name="project_image"
					onFileSelect={handleFileSelect}
					value={selectedImage}
					buttonProps={{ color: "primary", variant: "contained" }}
				/>
				<FormGroup>
					<FormControlLabel
						control={<Switch onChange={handleGotAnimation} />}
						label="Have animation or video?"
					/>
				</FormGroup>
				{haveAnimation && (
					<ImagePicker
						withDragDrop
						name="animation_file"
						onFileSelect={handleAnnimationSelect}
						value={selectedAnimationFile}
						buttonProps={{ color: "primary", variant: "contained" }}
						accept="image/gif, video/*"
					/>
				)}
				<TextField
					fullWidth
					helperText="This must be a unique code, as the NFT will be created based on this"
					label="SKU"
					margin="normal"
					name="item_sku"
					type="text"
					variant="outlined"
				/>
				<TextField
					fullWidth
					label="Item Name"
					margin="normal"
					name="item_name"
					type="text"
					variant="outlined"
				/>
				<TextField
					fullWidth
					multiline
					rows={4}
					label="Description"
					margin="normal"
					name="description"
					variant="outlined"
				/>
				<Grid container direction="row" justify="flex-start" alignItems="flex-start" mb={1} spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label="Units in Box"
							margin="normal"
							name="units_in_box"
							type="number"
							variant="outlined"
							inputProps={{ min: "1" }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label="Year of Production"
							margin="normal"
							name="year"
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Grid container direction="row" justify="flex-start" alignItems="flex-start" mb={1} spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField fullWidth label="Lot Number" margin="normal" name="lot_number" variant="outlined" />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label="Product Code"
							margin="normal"
							name="product_code"
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Grid container direction="row" justify="flex-start" alignItems="flex-start" mb={1} spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField fullWidth label="Origin" margin="normal" name="origin" variant="outlined" />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label="Manufactured At"
							margin="normal"
							name="manufactured_at"
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Grid container direction="row" justify="flex-start" alignItems="flex-start" mb={1} spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label="Availability"
							margin="normal"
							name="availability"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField fullWidth label="Wine Type" margin="normal" name="wine_type" variant="outlined" />
					</Grid>
				</Grid>
				<TextField
					fullWidth
					label="VineArt Certificate No."
					margin="normal"
					name="certificate_no"
					type="text"
					variant="outlined"
				/>
				<Box>
					{selectedCertificate && <Typography variant="caption">{selectedCertificate}</Typography>}
					<input
						id="upload-certificate-selector"
						type="file"
						className={classes.fileInput}
						onChange={handleCertificateSelect}
					/>
					<label htmlFor="upload-certificate-selector">
						<Button variant="contained" color="primary" component="span">
							Upload Certificate
						</Button>
					</label>
				</Box>
				<TextField
					fullWidth
					label="Mint on behalf of another Wallet Address"
					helperText="If specified, the NFT will be owned by the given wallet address"
					margin="normal"
					name="wallet_address"
					type="text"
					variant="outlined"
				/>
				<Box sx={{ pt: 2 }}>
					<Button
						color="primary"
						disabled={
							formik.isSubmitting ||
							!formik.isValid ||
							(!formik.touched.item_sku && !formik.isInitialValid)
						}
						fullWidth
						size="large"
						type="submit"
						variant="contained"
					>
						{formik.isSubmitting ? <CircularProgress size={20} /> : "Create"}
					</Button>
				</Box>
				<Typography variant="caption" component="p" sx={{ mt: 2 }} color="error" align="center">
					You are connected to {networkName(blockchainInfo ? blockchainInfo.networkId : 0)} with account{" "}
					{shortAccount(blockchainInfo ? blockchainInfo.account : null)}
				</Typography>
			</Form>
		</FormikProvider>
	);
};
export default ItemForm;
