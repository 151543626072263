import { uploadFile as uploadFileToCache, checkFileExists as checkFileCached } from "src/plugins/Cloudinary";
import { storage } from "src/plugins/Firebase";
import ipfs from "src/plugins/ipfs";

export const readBlobToBuffer = file =>
	new Promise((resolve, reject) => {
		if (file) {
			const reader = new window.FileReader();
			reader.readAsArrayBuffer(file);
			reader.onloadend = () => {
				resolve(Buffer(reader.result));
			};
		} else {
			reject("Must provide a file object");
		}
	});

export const uploadFileToIPFS = (fileBuffer, fileType = "image") =>
	new Promise((resolve, reject) => {
		ipfs.files.add(fileBuffer, (error, result) => {
			if (error) {
				reject(error);
			}

			const IPFSHash = result[0].hash;

			const fileBlob = convertBufferToBlob(fileBuffer);

			const mimeType =
				fileType === "video" ? "video/mp4" : fileType === "json" ? "application/json" : "image/png";

			//upload file to storage as well
			const storageRef = storage.ref(`/${IPFSHash}`);
			storageRef
				.getMetadata()
				.then(metadata => {
					// file was previously uploaded to storage
					// skip it
					console.log('skipping', IPFSHash, metadata);
				})
				.catch(error => {
					storageRef
						.put(fileBlob, {
							contentType: mimeType,
						})
						.then(snapshot => {
							console.log("uploaded to storage", snapshot);
						});
				});

			if (fileType !== "json") {
				// check if not already cached
				checkFileCached(IPFSHash, fileType).catch(error => uploadFileToCache(fileBlob, IPFSHash, fileType));
			}

			resolve(IPFSHash);
		});
	});

export const cachedVersion = (url, fileType = "image", width = null, height = null) => {
	if (!url) return "";

	// for now using cloudinary CDN for caching
	let cachedURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
	let filename = url;

	const path = url.split("/");
	const IPFSHash = path[path.length - 1];

	if (fileType === "video") {
		cachedURL += "video/upload/";
		filename = "videos/" + IPFSHash;
	} else {
		cachedURL += fileType + "/upload/";
		filename = "images/" + IPFSHash;
	}

	// if (width || height) {
	// 	cachedURL += "c_scale";
	// 	if (fileType === "image") cachedURL += ",q_100";
	// 	if (width) cachedURL += ",w_" + width;
	// 	if (height) cachedURL += ",h_" + height;
	// 	cachedURL += "/";
	// }

	return cachedURL + filename;
};

export const convertBufferToBlob = fileBuffer => new Blob([fileBuffer]);
