import { round } from "src/helpers/number.helper";
import { useCurrencyState } from "src/state";

const ActivityBlock = ({
  title,
  address,
  addressLink,
  amount,
  currency,
  timestamp,
  blockchainLink,
}) => {
  const { getExRateInUSD } = useCurrencyState();
  return (
    <div className="activity-container">
      <div className="css-942keq">
        <div className="css-w6k6xy">
          <div className="css-1vo2tlx">
            <div className="css-68da1">
              {title}
              <div className="css-12hibeq">
                <div className="css-1jm49l2" aria-expanded="false">
                  <a className="css-1btdx2y" href={addressLink} target="_blank">
                    <div className="css-1sdwxkz">{address}</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="css-fm72rd">{timestamp}</div>
          </div>
        </div>
        {amount && currency && (
          <div className="css-5avtf0">
            <div className="css-1lrq472">
              <div className="css-i44hey">
                {amount} {currency}
              </div>
              <div className="css-rhj7lr">
                ${round(amount * getExRateInUSD(currency))}
              </div>
            </div>
          </div>
        )}
      </div>
      {blockchainLink ? (
        <a
          className="css-1ibxqar"
          href={blockchainLink}
          target="_blank"
          rel="noreferrer"
          aria-label="View on Polygonscan"
        >
          <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            className="css-13o7eu2"
          >
            <path
              d="M15 0H8v2h4.6L6.3 8.3l1.4 1.4L14 3.4V8h2V1c0-.6-.4-1-1-1z"
              fill="currentColor"
            ></path>
            <path
              d="M14 16H1c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h4v2H2v11h11v-3h2v4c0 .6-.4 1-1 1z"
              fill="currentColor"
            ></path>
          </svg>
        </a>
      ) : <></>}
    </div>
  );
};

export default ActivityBlock;
