import { Box } from "@material-ui/core";
import { LinkableExternal } from "src/components/Linkable";

const ListingViewOnBlock = ({ title, linkTo, icon, onClick }) => (
  <LinkableExternal isLinked={linkTo ? true : false} to={linkTo}>
    <Box className="listing-view-block-container" onClick={onClick ? onClick : () => {}}>
      {/* {icon && <span className="css-1t7h2t3">{icon()}</span>} */}
      <div className="css-o5ua0">{title}</div>
      {/* {linkTo && (
        <div className="css-5i9hgn">
          <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            className="css-13o7eu2"
          >
            <path
              d="M15 0H8v2h4.6L6.3 8.3l1.4 1.4L14 3.4V8h2V1c0-.6-.4-1-1-1z"
              fill="currentColor"
            ></path>
            <path
              d="M14 16H1c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h4v2H2v11h11v-3h2v4c0 .6-.4 1-1 1z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      )} */}
    </Box>
  </LinkableExternal>
);

export default ListingViewOnBlock;
