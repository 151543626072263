import { createStyles, makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";

const useStyles = props =>
	makeStyles(() =>
		props.isAdmin
			? createStyles({
					"@global": {
						"*": {
							boxSizing: "border-box",
							margin: 0,
							padding: 0,
						},
						html: {
							"-webkit-font-smoothing": "antialiased",
							"-moz-osx-font-smoothing": "grayscale",
							height: "100%",
							width: "100%",
						},
						body: {
							color: "#000",
							backgroundColor: "#f4f6f8",
							height: "100%",
							width: "100%",
						},
						a: {
							textDecoration: "none",
						},
						"#root": {
							height: "100%",
							width: "100%",
						},
					},
			  })
			: {}
	)();

const GlobalStyles = () => {
	const location = useLocation();
	let isAdmin = false;
	if (location.pathname.search("/admin") >= 0 || location.pathname.search("/login") >= 0) isAdmin = true;

	useStyles({ isAdmin });

	return null;
};

export default GlobalStyles;
