const attributes = [
  {
    "title": "Item Code",
    "key": "item_sku"
  },
  {
    "title": "Production Year",
    "key": "year"
  },
  {
    "title": "Lot Number",
    "key": "lot_number"
  },
  {
    "title": "Product Code",
    "key": "product_code"
  },
  {
    "title": "Units in Box",
    "key": "units_in_box"
  },
  {
    "title": "Origin",
    "key": "origin"
  },
  {
    "title": "Manufactured At",
    "key": "manufactured_at"
  },
  {
    "title": "Availability",
    "key": "availability"
  },
  {
    "title": "Wine Type",
    "key": "wine_type"
  },
  {
    "title": "VineArt Certificate No.",
    "key": "certificate_no"
  }
]

const metadata_object = {
  "title": "{{item_name}}",
  "type": "object",
  "description": "{{description}}",
  "external_url": "https://xva.asia",
  "name": "{{item_name}}",
  "properties": {
    "name": {
      "type": "string",
      "description": "{{item_name}}"
    },
    "description": {
      "type": "string",
      "description": "{{description}}"
    }
  },
  "attributes": [],
}

attributes.map(item => metadata_object.attributes.push(
  [{
    [`{{#if ${item.key} != ''}}`]: {
      "trait_type": item.title,
      "value": `{{${item.key}}}`
    }
  }]
))

export default metadata_object;