export const uploadFile = (file, filename, type = "image", mode = "blob") =>
	new Promise((resolve, reject) => {
		const data = new FormData();
		if (mode === "blob") data.append("file", file, filename);
		else data.append("file", file);
		data.append(
			"upload_preset",
			type === "video"
				? process.env.REACT_APP_CLOUDINARY_VIDEO_PRESET
				: process.env.REACT_APP_CLOUDINARY_IMAGE_PRESET
		);
		data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);

		fetch(
			"https://api.cloudinary.com/v1_1/" + process.env.REACT_APP_CLOUDINARY_CLOUD_NAME + "/" + type + "/upload",
			{
				method: "post",
				body: data,
			}
		)
			.then(resp => resp.json())
			.then(data => {
				resolve(data.url);
			})
			.catch(err => {
				console.log(err);
				reject();
			});
	});

export const checkFileExists = (filename, type) =>
	new Promise((resolve, reject) => {
		const url =
			type === "video"
				? "https://res.cloudinary.com/" +
				  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME +
				  "/video/upload/videos/" +
				  filename
				: "https://res.cloudinary.com/" +
				  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME +
				  "/image/upload/images/" +
				  filename;

		fetch(url)
			.then(resp => {
				if (resp.status === 200) resolve(url);
				else reject();
			})
			.catch(err => {
				reject();
			});
	});
