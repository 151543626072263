import { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import ListingItem from "src/components/frontend/ListingItem";

const Collections = ({ collections, showEmpty, title, ...props }) => {
	return (
		<section id={title} className="section">
			<div className="container collection-preview">
				<div className="row" style={{ justifyContent: "flex-start" }}>
					<div className="col-lg-2 col-12">
						<div className="section-title-container">
							<h2 className="section-title">
								{title} <span>Collection</span>
							</h2>
						</div>
					</div>
					<div className="col-lg-10 col-12">
						{collections.length > 0 ? (
							<div className="row listings">
								{collections.map((collection, index) =>
									collection ? (
										<ListingItem
											key={collection.address}
											listing={{
												collectionID: collection.address,
												tokenID: 1,
												type: "collection",
											}}
											name={`${collection.name} (${collection.symbol})`}
											description={collection.description}
											linkTo={
												collection.totalItems == 1
													? `/listing/${collection.address}/1`
													: `/collection/${collection.address}`
											}
											showEmpty={showEmpty ?? false}
										/>
									) : null
								)}
							</div>
						) : (
							<Typography>There are no items to show, please check back again!</Typography>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Collections;
