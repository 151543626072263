import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { useGlobalState, useUserState } from "src/state";
import { AddToWalletIcon } from "src/components/frontend/icons";

const Connect = () => {
  const blockchainInfo = useContext(BlockchainContext);
  const { getRedirectToAfterConnect, setRedirectToAfterConnect } =
    useGlobalState();

  const navigate = useNavigate();

  const { profile } = useUserState();

  const connect = () => {
    if (blockchainInfo) blockchainInfo.connectToBlockchain();
  };

  useEffect(() => {
    if (blockchainInfo.account && profile) {
      if (
        window.location.hash &&
        window.location.hash === "#buy" &&
        profile.username === ""
      ) {
        navigate("/profile/settings", { replace: true });
      } else {
        const redirectTo = getRedirectToAfterConnect();
        if (redirectTo) {
          setRedirectToAfterConnect(null);
          navigate(redirectTo, { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    }
  }, [blockchainInfo, profile]);

  return (
    <section class="section">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "50%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <h1 className="section-title">
            Connect your Wallet
          </h1>
          <Typography align="center">
            Connect with one of our available wallet info providers or create a
            new one.
          </Typography>
          <List
            sx={{
              width: "100%",
              my: 3,
              bgcolor: "rgba(0,0,0,0.8)",
              borderRadius: "20px",
              cursor: "pointer"
            }}
          >
            <ListItem onClick={connect} className="anchor">
              <ListItemAvatar>
                <Avatar>
                  <AddToWalletIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Metamask" secondary="popular" />
            </ListItem>
          </List>
          <Typography variant="caption" align="center">
            Unable to find your preferred wallet provider? Bear with us, we are
            adding more to the list.
          </Typography>
          <h1 className="section-title">
            or
          </h1>
          <div style={{margin: "50px 0", textAlign: "center"}}>
            <img src="/assets/img/pay-by-cc.png" />
          </div>
        </Container>
      </Box>
    </section>
  );
};

export default Connect;
