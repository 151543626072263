import { Box, Typography } from "@material-ui/core";
import SquareBlock from "src/components/frontend/SquareBlock";
import { makeStyles } from "@material-ui/styles";

// const useStyles = makeStyles(theme => ({
// 	attributeBlock: {
// 		padding: "20px !important",
// 		border: "1px solid #CCC",
// 		background: "#FFF",
// 		display: "flex",
// 		flexDirection: "column !important",
// 		justifyContent: "center",
// 		borderRadius: "20px",
// 		boxShadow: "var(--st--shadows-0)",
// 	},
// 	attributeContent: {
// 		width: "100%",
// 		height: "100%",
// 		display: "flex",
// 		flexDirection: "column",
// 		justifyContent: "center",
// 		wordBreak: "break-word",
// 	},
// }));

const TokenAttribute = ({ title, value }) => {
	return (
		<div className="item-attribute">
			{value}
			<div className="item-attribute-title">{title}</div>
		</div>
	);
};

export default TokenAttribute;
