import { Box, Container, Typography } from "@material-ui/core";
import notFoundSVG from "../themes/default/assets/img/page_not_found.svg";

const NotFound = () => (
	<section className="section">
		<div className="container">
			<header id="home" className="hero-area">
				<div className="container">
					<div className="row space-100">
						<div className="col-lg-6 col-md-12 col-xs-12">
							<div className="contents">
								<p>
									<img
										src="/assets/img/logo.png"
										style={{maxHeight: "150px", marginBottom: "25px"}}
										alt=""
									/>
								</p>
								<h2 className="section-title">404: The page you are looking for isn't here</h2>
								<p>
									You either tried some shady route or you came here by mistake. Whichever it is, try
									using the navigation
								</p>
								<div className="header-button">
									<a href="https://marketplace.xva.asia/" className="btn btn-border-filled">
										Home Page
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</div>
	</section>
);

export default NotFound;
