import { storageURL } from "src/plugins/Firebase";
import { admin_base_path, listing_base_path } from "src/routes";

export const admin_link = path => admin_base_path + path;

export const listing_link = (collectionID, listingID) => listing_base_path + "/" + collectionID + "/" + listingID;

export const ipfs_link = ipfs_url => (ipfs_url ? ipfs_url.replace("ipfs://", "https://ipfs.io/") : "");

export const storage_link = ipfs_url => (ipfs_url ? ipfs_url.replace("https://ipfs.io/ipfs/", storageURL) : "");

export const hash_to_ipfs_link = hash => "https://ipfs.io/ipfs/" + hash;

function youtube_parser(url) {
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	var match = url.match(regExp);
	return match && match[7].length == 11 ? match[7] : false;
}

export const youtube_thumbnail_link = youtube_url => {
	const id = youtube_parser(youtube_url);
	if (id) {
		return `https://img.youtube.com/vi/${id}/1.jpg`;
	}

	return "";
};

export const youtube_embed_link = youtube_url => {
	const id = youtube_parser(youtube_url);
	if (id) {
		return `https://www.youtube.com/embed/${id}?autoplay=1&modestbranding=1`;
	}

	return "";
};
